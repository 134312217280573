<template>
  <div class="container">
    <div class="header">
      <h2 class="title-underline">
        {{ cmsStore.components.become_a_friend_form.fgImReadyToBecomeA }}
        <span>{{ cmsStore.components.become_a_friend_form.fgFriend }}.</span>
      </h2>
    </div>

    <div class="info">
      <p>
        {{ cmsStore.components.become_a_friend_form.fgJoiningAsAFriend }}
      </p>
    </div>

    <div>
      <p>
        {{ cmsStore.components.become_a_friend_form.fgPleaseChooseAPassword }}:
      </p>

      <ul class="list">
        <li>
          {{ cmsStore.components.become_a_friend_form.fgMoreCharacters }}
        </li>
        <li>
          {{ cmsStore.components.become_a_friend_form.fgNumericalCharacter }}
        </li>
        <li>{{ cmsStore.components.become_a_friend_form.fgDoesNotInclude }}</li>
      </ul>

      <p class="hint">
        {{ cmsStore.components.become_a_friend_form.fgAllFieldsMarked }}
        <span class="star">*</span>
        {{ cmsStore.components.become_a_friend_form.fgAreRequired }}
      </p>
    </div>

    <Input type="text" v-model.trim="email" :error="v$.email" allowAutofill="new-password">
    {{ cmsStore.components.become_a_friend_form.fgEmailAddress }}
    <span class="star">*</span>
    </Input>

    <div class="input-row">
      <Input :type="passwordType" which="password" allowAutofill="new-password" v-model="password" :error="v$.password"
        @iconClick="handleIconClick" :animateIcon="shouldPasswordIconAnimate">
      {{ cmsStore.components.become_a_friend_form.fgPassword }}
      <span class="star">*</span>
      </Input>

      <Input :type="passwordConfirmType" which="passwordConfirm" v-model="confirmPassword" :error="v$.confirmPassword"
        allowAutofill="new-password" @iconClick="handleIconClick" :animateIcon="shouldPasswordConfirmIconAnimate">
      {{ cmsStore.components.become_a_friend_form.fgConfirmPassword }}
      <span class="star">*</span>
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model.trim="firstName" :error="v$.firstName">
      {{ cmsStore.components.become_a_friend_form.fgFirstName }}
      <span class="star">*</span>
      </Input>

      <Input type="text" v-model.trim="lastName" :error="v$.lastName">
      {{ cmsStore.components.become_a_friend_form.fgLastName }}
      <span class="star">*</span>
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model.trim="address1" :error="v$.address1">
      {{ cmsStore.components.become_a_friend_form.fgStreetAddress1 }}
      <span class="star">*</span>
      </Input>

      <Input type="text" v-model.trim="address2" :error="v$.address2">
      {{ cmsStore.components.become_a_friend_form.fgStreetAddress2 }}
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model.trim="city" :error="v$.city">
      {{ cmsStore.components.become_a_friend_form.fgCity }}
      <span class="star">*</span>
      </Input>

      <!-- Non-USA countries will have a regular text input for the 'state' data field -->
      <Input v-if="!isUS(country)" type="text" v-model="stateNonUSA" :error="v$.stateNonUSA">
      {{ cmsStore.components.become_a_friend_form.fgState }}
      <span class="star">*</span>
      </Input>
      <Select v-if="isUS(country)" :list="apiCallsStore.stateList" @select-id="setStateAbbv" v-model="state"
        :error="v$.state">
        {{ cmsStore.components.become_a_friend_form.fgState }}
        <span class="star">*</span>
      </Select>
    </div>

    <div class="input-row">
      <Input type="text" :isDisabled="!isCountryUSA" v-model.trim="zip" :error="v$.zip">
      {{ cmsStore.components.become_a_friend_form.fgZipCode }}
      <span v-if="isCountryUSA" class="star">*</span>
      </Input>

      <Select :list="apiCallsStore.countryList" @select-item="setCountry" v-model="country" :error="v$.country">
        {{ cmsStore.components.become_a_friend_form.fgCountry }}
        <span class="star">*</span>
      </Select>
    </div>

    <div class="input-row input-row--short">
      <Input type="tel" v-model="phone" :error="v$.phone">
      {{ cmsStore.components.become_a_friend_form.fgPhoneNumber }}
      </Input>
    </div>

    <p v-if="isUS(country)" class="section-title">
      {{ cmsStore.components.become_a_friend_form.fgTestamentPreference }}
    </p>

    <p v-if="isUS(country)" class="testament-explanation">
      {{ cmsStore.components.become_a_friend_form.fgTestamentExplanation }}
    </p>

    <div v-if="isUS(country)" class="radio-row">
      <div class="radio">
        <input type="radio" id="ESV" value="ESV" v-model="testamentPreference" />
        <label for="ESV">{{ cmsStore.components.become_a_friend_form.fgESV }}</label>
      </div>
      <div class="radio">
        <input type="radio" id="KJV" value="KJV" v-model="testamentPreference" />
        <label for="KJV">{{ cmsStore.components.become_a_friend_form.fgKJV }}</label>
      </div>
      <div class="radio">
        <input type="radio" id="Spanish" value="Spanish" v-model="testamentPreference" />
        <label for="Spanish">{{ cmsStore.components.become_a_friend_form.fgSpanish }}</label>
      </div>
    </div>

    <p class="section-title">Tell us about your church (optional)</p>

    <Input type="text" v-model.trim="churchName" :error="v$.churchName">
    {{ cmsStore.components.become_a_friend_form.fgChurchName }}
    </Input>

    <div class="input-row">
      <Input type="text" v-model.trim="churchAdr" :error="v$.churchAdr">
      {{ cmsStore.components.become_a_friend_form.fgStreetAddress }}
      </Input>

      <Input type="text" v-model.trim="churchCity" :error="v$.churchCity">
      {{ cmsStore.components.become_a_friend_form.fgCity }}
      </Input>
    </div>

    <div class="input-row">
      <Select :list="apiCallsStore.stateList" @select-item="setStateChurch" v-model="churchState"
        :error="v$.churchState">
        {{ cmsStore.components.become_a_friend_form.fgState }}
      </Select>

      <Input type="text" v-model="churchZip" :error="v$.churchZip">
      {{ cmsStore.components.become_a_friend_form.fgZipCode }}
      </Input>
    </div>

    <p class="section-title">
      {{ cmsStore.components.become_a_friend_form.fgWhoReferred }}
    </p>

    <div class="radio-row radio-row-refer-type">
      <div class="radio">
        <input type="radio" id="gideon" value="Gideon" v-model="refMemType" />
        <label for="gideon">{{ cmsStore.components.become_a_friend_form.fgGideon }}</label>
      </div>

      <div class="radio">
        <input type="radio" id="auxiliary" value="Auxiliary" v-model="refMemType" />
        <label for="auxiliary">{{ cmsStore.components.become_a_friend_form.fgAuxiliary }}</label>
      </div>

      <div class="radio">
        <input type="radio" id="friend" value="Friend" v-model="refMemType" />
        <label for="friend">{{ cmsStore.components.become_a_friend_form.fgAnExistingFriend }}</label>
      </div>

      <div class="radio">
        <input type="radio" id="other" value="Other" v-model="refMemType" />
        <label for="other">{{ cmsStore.components.become_a_friend_form.fgOther }}</label>
      </div>
    </div>

    <div class="input-row">
      <Input type="text" v-model.trim="refMemFirstName" :error="v$.refMemFirstName">
      {{ cmsStore.components.become_a_friend_form.fgFirstName }}
      </Input>

      <Input type="text" v-model.trim="refMemLastName" :error="v$.refMemLastName">
      {{ cmsStore.components.become_a_friend_form.fgLastName }}
      </Input>
    </div>

    <div class="input-row">
      <Input type="text" v-model.trim="refMemAdr" :error="v$.refMemAdr">
      {{ cmsStore.components.become_a_friend_form.fgStreetAddress }}
      </Input>

      <Input type="text" v-model.trim="refMemCity" :error="v$.refMemCity">
      {{ cmsStore.components.become_a_friend_form.fgCity }}
      </Input>
    </div>

    <div class="input-row">
      <Select :list="apiCallsStore.stateList" @select-item="setReferralState" v-model="refMemState"
        :error="v$.refMemState">
        {{ cmsStore.components.become_a_friend_form.fgState }}
      </Select>

      <Input type="text" v-model="refMemZip" :error="v$.refMemZip">
      {{ cmsStore.components.become_a_friend_form.fgZipCode }}
      </Input>
    </div>

    <div class="input-row">
      <p v-if="formErrors" class="register-error">
        {{ cmsStore.components.become_a_friend_form.fgThereAreErrors }}
      </p>
      <p v-if="registerError" class="register-error">{{ registerError }}</p>
    </div>

    <div class="input-row">
      <button type="submit" class="button btn btn-default"
        v-bind:class="{ 'form-actively-submitting': formActivelySubmitting }" @click="formSubmit">
        {{ cmsStore.components.become_a_friend_form.fgSubmit }}

      </button>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Input";
import Select from "@/components/Select";
import H2 from "@/components/H2";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  maxLength,
  required,
  sameAs,
} from "@vuelidate/validators";

import { mapStores } from "pinia";
import { useApiCallsStore } from "@/stores/apiCallsStore";
import { useAuthStore } from "@/stores/authStore";
import { useCmsStore } from "@/stores/cmsStore";
import { useDesignStore } from "@/stores/designStore";
import { inputMixin } from "@/mixins/inputMixin";
import { storeMixin } from "@/mixins/storeMixin";

export default {
  name: "BecomeAFriendForm",
  mixins: [inputMixin, storeMixin],
  components: { Input, Select, H2 },
  setup: () => ({ v$: useVuelidate({ $lazy: false, $autoDirty: true }) }),
  data() {
    return {
      h2: {
        title: "Im Ready to Become a Friend",
        id: "t1",
        color: "color-black",
      },
      formActivelySubmitting: false,
      formErrors: false,
      isCountryUSA: true,
      registerError: false,
      address1: "",
      address2: "",
      churchAdr: "",
      churchCity: "",
      churchName: "",
      churchState: "",
      churchZip: "",
      city: "",
      confirmPassword: "",
      country: "United States",
      firstName: "",
      email: "",
      lastName: "",
      password: "",
      passwordType: "password",
      passwordConfirmType: "password",
      phone: "",
      refMemAdr: "",
      refMemCity: "",
      refMemFirstName: "",
      refMemLastName: "",
      refMemState: "",
      refMemType: "",
      refMemZip: "",
      shouldPasswordIconAnimate: false,
      shouldPasswordConfirmIconAnimate: false,
      state: "",
      stateAbbv: "",
      stateNonUSA: "",
      testamentPreference: "ESV",
      zip: "",
    };
  },
  mounted() {
    this.designStore.setIsLogo(false);
  },
  async created() {
    await this.getStateAndCountryLists().then(results => {
    });
  },
  computed: {
    ...mapStores(useApiCallsStore, useAuthStore, useCmsStore, useDesignStore),
  },
  methods: {
    async apiCallToRegisterFriend(newFriend) {
      try {
        return await this.apiCallsStore
          .RegisterNewFriend(newFriend)
          .then((response) => {
            return response;
          });
      } catch (e) {
        console.error(e);
      }
    },
    buildFriendJson() {
      const {
        address1,
        address2,
        churchAdr,
        churchCity,
        churchName,
        churchState,
        churchZip,
        city,
        country,
        firstName,
        email,
        lastName,
        password,
        phone,
        refMemAdr,
        refMemCity,
        refMemFirstName,
        refMemLastName,
        refMemState,
        refMemZip,
        testamentPreference,
        zip,
      } = this;

      return {
        RegistrationId: null,
        OrgName: null,
        MemberNumber: null,
        CstKey: null,
        DateOfBirthDay: null,
        DateOfBirthMonth: null,
        DateOfBirthYear: null,
        Newsletter: true,
        Gender: null,
        DateTimeStamp: new Date(),
        Application: "Friends",
        FriendType: "Friend",
        EnrollmentDate: null,
        AnniversaryDate: null,
        PremiumCount: null,
        PremiumOptOut: null,
        ScriptureVersion: testamentPreference,
        TestamentType: testamentPreference,
        TotalAmount: null,
        MonthlyAmount: null,
        Address1: address1 ? address1 : null,
        Address2: address2 ? address2 : null,
        ChurchAdr: churchAdr ? churchAdr : null,
        ChurchCity: churchCity ? churchCity : null,
        ChurchName: churchName ? churchName : null,
        ChurchState: churchState ? churchState : null,
        ChurchZip: churchZip ? churchZip : null,
        ChurchCountry: "United States",
        City: city ? city : null,
        Country: country ? country : null,
        FirstName: firstName ? firstName : null,
        Email: email ? email : null,
        LastName: lastName ? lastName : null,
        Password: password ? password : null,
        Phone: phone ? phone : null,
        RefGideonAddress: refMemAdr ? refMemAdr : null,
        RefGideonCity: refMemCity ? refMemCity : null,
        RefGideonFirstName: refMemFirstName ? refMemFirstName : null,
        RefGideonLastName: refMemLastName ? refMemLastName : null,
        RefGideonState: refMemState ? refMemState : null,
        RefGideonZip: refMemZip ? refMemZip : null,
        RefGideonCountry: "United States",
        State: this.isUS(country) ? this.stateAbbv : this.stateNonUSA,
        Zip: zip ? zip : '00000',
      };
    },
    async formSubmit() {
      if (this.formActivelySubmitting) return false;

      this.setFormErrorMessage(false); // reset form error message
      this.setRegisterErrorMessage(""); // reset register error message
      this.setFormBeingActivelySubmitted(true);

      let loader = this.$loading.show();
      const isFormValid = await this.v$.$validate();

      try {
        if (isFormValid) {
          const newFriend = this.buildFriendJson();
          const response = await this.apiCallToRegisterFriend(newFriend);
          await this.handleServerResponse(response);
        } else {
          this.setFormErrorMessage(true);
          return false;
        }
      } catch (e) {
        console.error("Error in BecomeAFriend.vue, ", e);
      } finally {
        loader.hide();
        this.setFormBeingActivelySubmitted(false);
      }
    },
    async handleServerResponse(res) {
      let errMessage =
        "There was an issue during your registration, please try again.";
      if (res && typeof res === "object" && res.cstKey) {
        errMessage = "Success! Logging in...";
        const newFriend = {
          username: this.email,
          password: this.password,
        };
        await this.authStore
          .login(newFriend)
          .then(
            () => {
              this.$router.push({ name: 'Friend' })
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          )
          .finally(() => {
          });
      }

      if (res && typeof res === "string") {
        errMessage = res;
      }
      this.setRegisterErrorMessage(errMessage);
    },
    async getStateAndCountryLists() {
      return await this.apiCallsStore.GetStatesAndCountries(true, "United States");
    },
    setFormErrorMessage(status) {
      this.formErrors = status;
    },
    setRegisterErrorMessage(message) {
      this.registerError = message;
    },
    setFormBeingActivelySubmitted(status) {
      this.formActivelySubmitting = status;
    },
    setState(payload) {
      this.state = payload.name;
    },
    setStateAbbv(payload) {
      this.stateAbbv = payload;
    },
    setCountry(payload) {
      this.country = payload.name

      if (payload.name && this.isUS(payload.name)) {
        this.isCountryUSA = true
        this.stateNonUSA = ''
        this.zip = ''
      } else {
        this.isCountryUSA = false
        this.state = ''
        this.stateAbbv = ''
        this.zip = ''
      }
    },
    setStateChurch(payload) {
      this.churchState = payload.name;
    },
    setReferralState(payload) {
      this.refMemState = payload.name;
    },
  },
  validations() {
    const atLeastOneLetter = (value) => /[a-zA-Z]/.test(value);
    const atLeastOneNumber = (value) => /[0-9]/.test(value);
    const atLeastSixCharactersLong = (value) => value.length > 5;
    const passwordAndEmailMustBeDifferent = value => !value.includes(this.email)
    const requireZipForUSAOnly = value => {
      if (this.isUS(this.country) && !value) {
        return false
      } else if (!this.isUS(this.country)) return true

      return true
    }
    const validStateForNonUSA = value => {
      if (!value && !this.isUS(this.country)) return false
      if (!this.isUS(this.country)) {
        if (value.length > 1) return true
      } else if (this.isUS(this.country)) return true

      return false
    }
    const validStateForUSA = value => {
      if (!value && this.isCountryUSA) return false
      if (this.isUS(this.country)) {
        const userState = this.apiCallsStore.stateList.filter(state => state.abbreviation === value || state.name === value)
        if (userState && userState.length > 0) return true
      } else if (!this.isUS(this.country)) return true

      return false
    }

    return {
      confirmPassword: {
        required: helpers.withMessage("Confirm your password please", required),
        sameAsRawValue: sameAs(this.password),
      },
      city: {
        required: helpers.withMessage("City is required", required),
        maxLengthValue: helpers.withMessage(
          "City cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        maxLengthValue: helpers.withMessage(
          "Email cannot be longer than 100 characters",
          maxLength(100)
        ),
        email,
      },
      firstName: {
        required: helpers.withMessage("First name is required", required),
        maxLengthValue: helpers.withMessage(
          "First name cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      lastName: {
        required: helpers.withMessage("Last name is required", required),
        maxLengthValue: helpers.withMessage(
          "Last name cannot be longer than 50 characters",
          maxLength(50)
        ),
      },
      password: {
        required: helpers.withMessage("A password is required", required),
        minLengthValue: helpers.withMessage(
          "Password must be at least 6 characters long",
          atLeastSixCharactersLong
        ),
        atLeastOneLetter: helpers.withMessage(
          "At least one letter is required",
          atLeastOneLetter
        ),
        atLeastOneNumber: helpers.withMessage(
          "At least one number is required",
          atLeastOneNumber
        ),
        passwordAndEmailMustBeDifferent: helpers.withMessage('Password cannot contain your email address', passwordAndEmailMustBeDifferent),
      },
      state: {
        validStateForUSA: helpers.withMessage('State selection is invalid', validStateForUSA),
      },
      stateNonUSA: {
        validStateForNonUSA: helpers.withMessage('State selection is invalid', validStateForNonUSA),
      },
      zip: {
        requireZipForUSAOnly: helpers.withMessage('Zip code is required', requireZipForUSAOnly),
      },
      country: {
        required: helpers.withMessage("Country is required", required),
      },
      address1: {
        required: helpers.withMessage("Address is required", required),
        maxLengthValue: helpers.withMessage(
          "Address 1 cannot be longer than 200 characters",
          maxLength(200)
        ),
      },
      address2: {
        maxLengthValue: helpers.withMessage(
          "Address 1 cannot be longer than 200 characters",
          maxLength(200)
        ),
      },
    };
  },
};
</script>


<style lang="scss" scoped>
.container {
  position: relative;
  background: #fff;
  padding: 50px 75px 50px 50px;
  max-width: 1050px;

  p {
    color: #0c2e3c;

    @media screen and (max-width: $sm) {}
  }

  ul {
    color: #0c2e3c;

    @media screen and (max-width: $sm) {}
  }

  @media screen and (max-width: 1024px) {
    padding: 50px;
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
  }
}

.header {
  margin-bottom: 45px;
}

.title-underline {

  span {
    font-weight: 900;
  }

  &:after {
    background: $yellow-darker;
    content: "";
    display: block;
    height: 10px;
    width: 180px;
    transition: width 0.25s;
    margin-top: 24px;
  }
}

.list {
  margin-left: 35px;
  margin-bottom: 20px;
  font-family: $gotham, sans-serif;
  font-weight: 400;

  li {
    margin-bottom: 15px;
  }
}

.hint {
  color: $blue;
  font-size: 16px;
  margin-bottom: 42px;

  span {
    color: $red;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 22px;
    margin-top: 20px;
  }
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  :deep {
    .select-container {
      .title-label {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 33px;
        color: #0c2e3c;
      }
    }
  }

  div:first-child {
    margin-right: 37px;
  }

  &--short {
    max-width: 410px;

    div:first-child {
      margin-right: 0;
    }
  }

  .form-actively-submitting {
    cursor: not-allowed;
  }

  .input-error {
    color: $red;
    font-size: 18px;
    font-weight: 500;
  }

  .register-error {
    color: $red;
    font-size: 18px;
    font-weight: 500;
    white-space: pre-line;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    div:first-child {
      margin-bottom: 16px;
      margin-right: 0;
    }

    &--short {
      max-width: 100%;

      div:first-child {
        margin-right: 0;
      }
    }
  }
}

.section-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  color: $blue-darker;
  margin-bottom: 20px;
  margin-top: 50px;
}

.radio-row {
  display: flex;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.radio-row-refer-type {
  @media screen and (max-width: 1500px) {
    flex-direction: column;
  }
}

.radio-row-refer-type div.radio {
  @media screen and (min-width: 770px) {
    margin-bottom: 12px;
  }
}

.radio {
  input {
    margin-right: 15px;
  }

  label {
    margin-right: 60px;
    font-size: 15px;
    color: $blue-darker;
  }
}

.role-caption {
  font-size: 16px;
  font-weight: 700;
  line-height: 33px;
  color: $blue-darker;
}

.button {
  margin-top: 60px;

  @media screen and (max-width: 768px) {
    margin-top: 26px;
  }
}

.star {
  color: $red;
  font-size: 20px;
}

p.testament-explanation {
  color: $blue;
  font-size: 14px;
}
</style>
