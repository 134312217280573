<template>
  <FormTemplate header="Become A Friend" sub-header="Join friends of Gideons">
    <BecomeAFriendForm/>
  </FormTemplate>
</template>

<script>
import FormTemplate from '@/components/FormTemplate';
import BecomeAFriendForm from '@/components/BecomeAFriendForm';
import {mapStores} from 'pinia'
import {useCmsStore} from "@/stores/cmsStore"
import inView from 'in-view';

export default {
  name: 'BecomeAFriend',
  components: {
    FormTemplate,
    BecomeAFriendForm
  },
  data() {
    return {
      cms: {
        loaded: false,  // set to 'true' when CMS content has been returned from API
        options: {
          Common: true,
          Components: ["become_a_friend_form", "form_template", "h2", "input", "login_modal", "select"],
          LanguageCode: "",
          PageName: "become_a_friend",
          MinistryUpdates: false
        },
        page: {},
      },
    }
  },
  computed: {
    ...mapStores(useCmsStore),
  },
  async created() {
    this.setContentLoaded(false)
    await this.fetchCmsData()
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    async fetchCmsData() {
      try {
        await this.cmsStore.getPageAndOrCommonAndOrComponents(this.cms.options)
            .then(results => {
              if (results) {
                this.cms.page = results
              } else return false

              this.setContentLoaded(true)
              return true;
            })
      } catch (e) {
        console.error(e)
      }
    },
    setContentLoaded(loadedStatus) {
      this.cms.loaded = loadedStatus
    },
    handleScroll() {
      inView('.title-underline')
          .on('enter', el => {
            el.classList.add('inview');
          })
          .on('exit', el => {
            el.classList.remove('inview');
          });
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  }

};
</script>

<style lang="scss" scoped>
// empty
</style>
